import React, { useState } from 'react';
import { auth } from '../firebaseConfig';
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import Modal from './Modal';
import './Login.css';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar el modal en caso de error
    //const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate(); // Hook para redireccionar

    const handleLogin = async (event) => {
        event.preventDefault();
        //setErrorMessage(''); // Reinicia el mensaje de error

        try {
            // Intentar iniciar sesión con Firebase Authentication
            await signInWithEmailAndPassword(auth, email, password);
            navigate('/'); // Redirige a la página de inicio si el inicio de sesión es exitoso
        } catch (error) {
            console.error("Error al iniciar sesión:", error);
            //setErrorMessage("Error al iniciar sesión: " + error.message);
            setIsModalOpen(true); // Muestra el modal en caso de error
        }
    };

    return (
        <div className="form-container">
            <h2 className="form-title">Iniciar Sesión</h2>
            <form className="form" onSubmit={handleLogin}>
                <label className="form-label">Correo Electrónico</label>
                <input 
                    type="email" 
                    className="form-input" 
                    placeholder="correo@ejemplo.com" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    required 
                />
                
                <label className="form-label">Contraseña</label>
                <input 
                    type="password" 
                    className="form-input" 
                    placeholder="Tu contraseña" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    required 
                />
                
                <button type="submit" className="form-button">Iniciar Sesión</button>
            </form>

            {/* Modal de error */}
            <Modal 
                isOpen={isModalOpen} 
                onClose={() => setIsModalOpen(false)} 
                title="Error de Inicio de Sesión"
            >
                <p>{'Correo o contraseña incorrectas.'}</p>
            </Modal>
        </div>
    );
}

export default Login;
