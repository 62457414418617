import React from 'react';
import './Enconstruccion.css'
import ConstruccionImage from '../../img/EnContruccion.png'; // Ruta de la imagen que quieras usar

function EnConstruccion() {
    return (
        <div className="en-construccion-container">
            <img 
                src={ConstruccionImage} 
                alt="Página en construcción" 
                className="construccion-image" 
            />
            <h2 className="construccion-title">¡Estamos trabajando para ti! 🛠️</h2>
            <p className="construccion-text">
                Este sitio está en construcción. Pronto tendrás acceso a toda la información. ¡Gracias por tu paciencia!
            </p>
        </div>
    );
}

export default EnConstruccion;
