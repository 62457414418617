import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { onAuthStateChanged, signOut } from "firebase/auth";
import { ref, get } from "firebase/database";
import { auth, db } from './componentes/firebaseConfig';  
import Cabecera from './componentes/Cabecera/Cabecera';
import Inicio from './componentes/Inicio/Inicio';
import Productos from './componentes/Productos/Productos';
import TipsDeterMiau from './componentes/tipDeterMiau/tipDeterMiau';
import TipDetail from './componentes/tipDeterMiau/tipDetail/tipDetail'
import Beneficios from './componentes/Beneficios/Beneficios';
import Contacto from './componentes/Contacto/Contacto';
import Login from './componentes/Login/Login';
import Register from './componentes/Login/Register';
import Auth from './componentes/Login/Auth';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Footer from './componentes/Footer/footer';
import Dispensadores from './componentes/Dispensadores/Dispensadores'


function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userCredit, setUserCredit] = useState(0);

    // Detecta el estado de autenticación del usuario
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setIsAuthenticated(true);
                // Obtener el crédito del usuario desde Firebase Database
                const userRef = ref(db, `users/${user.uid}`);
                const snapshot = await get(userRef);
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    setUserCredit(data.credit); // Establece el crédito en el estado
                }
            } else {
                setIsAuthenticated(false);
                setUserCredit(0); // Reinicia el crédito si el usuario cierra sesión
            }
        });
        return () => unsubscribe(); // Limpia el listener cuando el componente se desmonta
    }, []);

    // Función para cerrar sesión
    const handleLogout = async () => {
        await signOut(auth);
        setIsAuthenticated(false);
        setUserCredit(0); // Reinicia el crédito al cerrar sesión
    };

    return (
        <Router>
            <Cabecera 
                isAuthenticated={isAuthenticated} 
                onLogout={handleLogout} 
                userCredit={userCredit} 
            />

        
                <Routes>
                    <Route path="/" element={<Inicio />} />
                    <Route path="/productos" element={<Productos />} />
                    <Route path="/BlogDeterMiau" element={<TipsDeterMiau />} />
                    <Route path="/Blog/:id" element={<TipDetail />} />
                    <Route path="/beneficios" element={<Beneficios isAuthenticated={isAuthenticated} />} />
                    <Route path="/contacto" element={<Contacto />} />
                    <Route path="/Dispensadores" element={<Dispensadores />} />
                    <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/auth" element={<Auth setIsAuthenticated={setIsAuthenticated} />} />
                </Routes>
           

            <Footer/>
        </Router>
    );
}

export default App;
