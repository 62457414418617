import React, { useState } from 'react';
import Login from './Login';
import Register from './Register';
import './Auth.css';

function Auth() {
    const [isLogin, setIsLogin] = useState(true);

    return (
        <div className="auth-container">
            <div className="auth-tabs">
                <button onClick={() => setIsLogin(true)} className={isLogin ? 'active' : ''}>Iniciar Sesión</button>
                <button onClick={() => setIsLogin(false)} className={!isLogin ? 'active' : ''}>Registrarse</button>
            </div>
            <div className="auth-form">
                {isLogin ? <Login /> : <Register />}
            </div>
        </div>
    );
}

export default Auth;
