import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './tipDetail.css';
import RopaBlanca from '../../../img/blog_img/Ropablanca.png'
import PinoImg from '../../../img/Pino.png'
import RRR from '../../../img/RRR.png';
import simbolosLavado from '../../../img/blog_img/simbolos.jpg'
import LavadoAMano from '../../../img/blog_img/Simbolos/LavadoAMano.PNG'
import CicloDeLabadoDelicado from '../../../img/blog_img/Simbolos/CicloLavadoDelicado.PNG'
import NoLavar from '../../../img/blog_img/Simbolos/NoLavar.JPG'
import SecadoSuave from '../../../img/blog_img/Simbolos/SecadoSuave.JPG'
import NoSecadora from '../../../img/blog_img/Simbolos/NoSecadora.JPG'
import PlancharBajaTemperatura from '../../../img/blog_img/Simbolos/PlancharBajaTemperatura.JPG'
import NoPlanchar from '../../../img/blog_img/Simbolos/NoPlanchar.JPG'


const RopaBlancaDetail = () => {
    const [comments, setComments] = useState([]); 
    const [comment, setComment] = useState(""); 
    const handleCommentChange = (e) => setComment(e.target.value); 
    const handleCommentSubmit = (e) => { 
        e.preventDefault(); 
        if (comment.trim()) { 
            setComments([...comments, comment]); 
            setComment(""); 
        } 
    };
    
    return (
    <div className="tip-detail-content">
        <div className="header-section">
            <h1>Cómo Mantener tu Ropa Blanca Impecable 🧺</h1>
            <p className="subtitle">Los mejores consejos para que tus prendas blancas luzcan como nuevas</p>
            <img src={RopaBlanca} alt="Ropa blanca impecable" className="banner-image" />
        </div>
        <div className="content-section">
            <p>
                Mantener las prendas blancas radiantes es un desafío común. Con el tiempo, es normal que la ropa blanca adquiera un tono amarillento o grisáceo debido al uso y los lavados frecuentes. Sin embargo, con los cuidados adecuados, puedes preservar la blancura y el brillo original de tus prendas. Aquí te presentamos una guía completa para lograrlo.
            </p>

            <h2>1. Lava tus prendas blancas por separado 🧺</h2>
            <p>
                El primer paso para mantener tus prendas blancas impecables es separarlas del resto de la ropa. Al lavarlas junto con prendas de colores, existe el riesgo de que los tintes se transfieran y manchen tus prendas blancas.
            </p>

            <h2>2. Utiliza agua fría 💧</h2>
            <p>
                Aunque puede parecer que el agua caliente limpia mejor, el agua fría es más suave con las fibras de la ropa. Ayuda a prevenir el encogimiento y el desgaste prematuro, manteniendo tus prendas en mejor estado por más tiempo.
            </p>

            <h2>3. Elige detergentes especiales 🧴</h2>
            <p>
                Para cuidar tus prendas, utiliza <strong>DeterMiau+</strong>, que contiene un agente que realza los colores, y <strong>OxiMiau</strong>, con oxígeno activo, que ayuda a eliminar manchas y deja tu ropa blanca aún más blanca.
            </p>

            <h2>4. Añade bicarbonato de sodio ✨</h2>
            <p>
                El bicarbonato de sodio es un aliado natural para realzar el blanco de tus prendas. Añade media taza al ciclo de lavado para neutralizar los olores y potenciar la acción del detergente.
            </p>

            <h2>5. Evita el uso excesivo de cloro 🚫</h2>
            <p>
                Aunque el cloro es efectivo para blanquear, su uso frecuente puede debilitar las fibras y causar un tono amarillento. Utilízalo con moderación y considera alternativas más suaves.
            </p>

            <h2>6. Pre-trata las manchas difíciles 🧐</h2>
            <p>
                Si detectas manchas antes del lavado, aplícales un poco de <strong>OxiMiau</strong>. Deja actuar por unos minutos para mejorar la eliminación durante el lavado.
            </p>


            <h2>7. Seca al sol siempre que sea posible 🌞</h2>
            <p>
                La luz solar es un blanqueador natural. Secar tus prendas blancas al sol no solo ayuda a eliminar bacterias, sino que también realza su blancura. Asegúrate de colgarlas del revés para evitar que los rayos UV dañen las fibras.
            </p>

            <h2>8. Plancha con cuidado 🧺</h2>
            <p>
                Planchar tus prendas blancas puede ayudarlas a lucir más presentables. Utiliza una temperatura adecuada según el tipo de tela y, de ser posible, plancha cuando la prenda aún esté ligeramente húmeda.
            </p>

            <div className="tips-extra">
                <h3>💡 Tips adicionales:</h3>
                <ul>
                    <li>
                        <strong>Evita sobrecargar la lavadora:</strong> Esto permite que las prendas se limpien adecuadamente y evita que la suciedad se redeposite.
                    </li>
                    
                    <li>
                        <strong>Almacenamiento adecuado:</strong> Guarda tus prendas blancas en lugares frescos y secos para evitar la aparición de manchas amarillas por humedad.
                    </li>
                </ul>
            </div>
            
        </div>
    </div>
    );
};


const PinoDetail = () => {
    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState('');
    const handleCommentChange = (e) => setComment(e.target.value);
    const handleCommentSubmit = (e) => {
        e.preventDefault();
        if (comment.trim()) {
            setComments([...comments, comment]);
            setComment('');
        }
    };

    return (
        <div className="tip-detail-content">
            <div className="header-section">
                <h1>El poder del Aceite de Pino 🌲</h1>
                <p className="subtitle">Descubre los beneficios de limpiar con pino DeterMiau</p>
                <img src={PinoImg} alt="Aceite de Pino" className="banner-image" />
            </div>
            <div className="content-section">
                <p>
                    Limpiar con pino no solo deja tu hogar con un aroma fresco y natural, sino que también aporta múltiples beneficios gracias a las propiedades únicas del aceite de pino. En <strong>DeterMiau</strong>, hemos desarrollado un limpiador de pino con aceite de pino natural de la más alta calidad, pensado para cuidar tu hogar y el medio ambiente.
                </p>

                <h2>1. Potente desinfectante 🦠</h2>
                <p>
                    El aceite de pino es conocido por sus propiedades antimicrobianas. El limpiador de pino DeterMiau elimina bacterias y gérmenes, manteniendo tus superficies impecables y seguras para toda tu familia.
                </p>

                <h2>2. Aroma fresco y duradero 🌿</h2>
                <p>
                    Nada se compara con la frescura natural del pino. Nuestro limpiador deja un aroma relajante y duradero en cada rincón de tu hogar, creando un ambiente acogedor y limpio.
                </p>

                <h2>3. Seguro para todo tipo de superficies 🧽</h2>
                <p>
                    Desde pisos hasta encimeras, el limpiador de pino DeterMiau es suave pero efectivo. Su fórmula biodegradable no daña tus superficies y es ideal para uso diario.
                </p>

                <h2>4. Poder natural contra la grasa ✨</h2>
                <p>
                    ¿Lidiando con manchas difíciles de grasa? El aceite de pino descompone la suciedad y la grasa con facilidad, dejando tus superficies brillantes y libres de residuos.
                </p>

                <h2>5. Eficiente en áreas exteriores 🏡</h2>
                <p>
                    Ideal para patios, terrazas y otras áreas exteriores. El limpiador de pino no solo limpia, sino que también desodoriza, manteniendo tus espacios exteriores tan agradables como los interiores.
                </p>

                <div className="tips-extra">
                    <h3>💡 Tips adicionales:</h3>
                    <ul>
                        <li>
                            <strong>Dilúyelo según tus necesidades:</strong> Una pequeña cantidad de nuestro limpiador es suficiente para grandes resultados. Dilúyelo en agua para limpiezas ligeras o úsalo concentrado para manchas difíciles.
                        </li>
                        <li>
                            <strong>Perfecto para desodorizar:</strong> Limpia tus cubos de basura o drenajes con nuestro limpiador para eliminar malos olores.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

const SimbolosDeLavado = () => {
    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState('');
    const handleCommentChange = (e) => setComment(e.target.value);
    const handleCommentSubmit = (e) => {
        e.preventDefault();
        if (comment.trim()) {
            setComments([...comments, comment]);
            setComment('');
        }
    };

    return (
        <div className="tip-detail-content">
            <div className="header-section">
                <h1>Símbolos de lavado ⚠️</h1>
                <p className="subtitle">Descubre el significado de los símbolos de lavado y aprende a cuidar tus prendas de la mejor manera.</p>
                <img src={simbolosLavado} alt="Aceite de Pino" className="banner-image" />
            </div>

            <div className="content-section">
                

                <div class="tip-detail-content"> 
                    <p class="description"> Para un cuidado textil óptimo, el primer paso es revisar la etiqueta de la prenda. Los símbolos de lavado impresos en la etiqueta proporcionan información esencial. Es crucial interpretar correctamente estos símbolos, especialmente para tejidos delicados. </p> 
                </div>

                <div class="image-section"> 
                    <img src={LavadoAMano} alt="Descripción de la imagen" class="section-image" /> 
                    <div class="image-text"> 
                        <h3 class="image-title">Lavado a mano</h3> 
                        <p class="description">Este símbolo de lavado significa que las prendas solo se pueden lavar a mano a un máximo de 30 °C (nosotros te proporcionaremos los consejos adecuados para el lavado a mano) o con un programa especial de lavado a mano para lana lavable a mano en la máquina.</p> 
                    </div> 
                </div>

                <div class="image-section"> 
                    <img src={CicloDeLabadoDelicado} alt="Descripción de la imagen" class="section-image" /> 
                    <div class="image-text"> 
                        <h3 class="image-title">Lavado a mano</h3> 
                        <p class="image-description">Este símbolo de lavado significa que las prendas solo se pueden lavar a mano a un máximo de 30 °C (nosotros te proporcionaremos los consejos adecuados para el lavado a mano) o con un programa especial de lavado a mano para lana lavable a mano en la máquina.</p> 
                    </div> 
                </div>
                
                <div class="image-section"> 
                    <img src={NoLavar} alt="Descripción de la imagen" class="section-image" /> 
                    <div class="image-text"> 
                        <h3 class="image-title">No lavar</h3> 
                        <p class="image-description">¿Has encontrado el símbolo de lavado en la etiqueta de tu nuevo suéter de lana favorito? Dichas prendas no deben lavarse a mano ni en la máquina. Lo mejor que puedes hacer en este caso es ponerte en contacto con una empresa de lavandería.</p> 
                    </div> 
                </div>

                <div class="image-section"> 
                    <img src={SecadoSuave} alt="Descripción de la imagen" class="section-image" /> 
                    <div class="image-text"> 
                        <h3 class="image-title">Secado suave</h3> 
                        <p class="image-description">Este símbolo de cuidado no se refiere al lavado en sí, sino al correcto cuidado posterior. Las prendas con este símbolo en la etiqueta se pueden secar en la secadora a temperatura reducida.</p> 
                    </div> 
                </div>

                <div class="image-section"> 
                    <img src={NoSecadora} alt="Descripción de la imagen" class="section-image" /> 
                    <div class="image-text"> 
                        <h3 class="image-title">No secar en secadora</h3> 
                        <p class="image-description">No es posible secar en la secadora prendas con este símbolo de cuidado en la etiqueta. Es mejor colgar la ropa y dejar que se seque al aire.</p> 
                    </div> 
                </div>

                <div class="image-section"> 
                    <img src={PlancharBajaTemperatura} alt="Descripción de la imagen" class="section-image" /> 
                    <div class="image-text"> 
                        <h3 class="image-title">Planchar a baja temperatura</h3> 
                        <p class="image-description">Como es natural, los símbolos de planchado no deben faltar en nuestra lista de símbolos de cuidado común para la lana. Según este símbolo de cuidado que se observa en la etiqueta, las prendas se podrán planchar a baja temperatura. Las prendas brillantes o sensibles a la presión deben plancharse con un paño de planchado seco o si fuera necesario, en el reverso.</p> 
                    </div> 
                </div>

                <div class="image-section"> 
                    <img src={NoPlanchar} alt="Descripción de la imagen" class="section-image" /> 
                    <div class="image-text"> 
                        <h3 class="image-title">No planchar</h3> 
                        <p class="image-description">Este símbolo de cuidado no deja dudas: No se puede planchar. La mejor opción es alisar la prenda y dejarla plana.</p> 
                    </div> 
                </div>






                {/* <div className="tips-extra">
                    <h3>💡 Tips adicionales:</h3>
                    <ul>
                        <li>
                            <strong>Dilúyelo según tus necesidades:</strong> Una pequeña cantidad de nuestro limpiador es suficiente para grandes resultados. Dilúyelo en agua para limpiezas ligeras o úsalo concentrado para manchas difíciles.
                        </li>
                        <li>
                            <strong>Perfecto para desodorizar:</strong> Limpia tus cubos de basura o drenajes con nuestro limpiador para eliminar malos olores.
                        </li>
                    </ul>
                </div> */}
            </div>
        </div>
    );
};

const EcoFriendlyDetail = () => {
    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState('');
    const handleCommentChange = (e) => setComment(e.target.value);
    const handleCommentSubmit = (e) => {
        e.preventDefault();
        if (comment.trim()) {
            setComments([...comments, comment]);
            setComment('');
        }
    };

    return (
        <div className="tip-detail-content">
            <div className="header-section">
                <h1>Limpieza ecoamigable 🌍</h1>
                <p className="subtitle">Reutiliza tus envases con nuestros despachadores automáticos</p>
                <img src={RRR} alt="Reutilizar envases" className="banner-image" />
            </div>
            <div className="content-section">
                <p>
                    En <strong>DeterMiau</strong>, queremos contribuir a la reducción de desechos plásticos promoviendo la reutilización de envases. Con nuestros productos de limpieza de la más alta calidad, puedes ayudar a disminuir el uso de plásticos y disfrutar de soluciones ecoamigables para tu hogar.
                </p>

                <h2>1. Menos desechos, más futuro 🌱</h2>
                <p>
                    Los desechos plásticos representan un desafío global. Reutilizando tus envases con nuestros despachadores automáticos, puedes ayudar a reducir la basura plástica y su impacto ambiental.
                </p>

                <h2>2. Rellena con calidad superior 🧼</h2>
                <p>
                    Nuestros despachadores no solo son cómodos y fáciles de usar, sino que también están diseñados para proporcionar productos de alta calidad. Desde jabones líquidos hasta detergentes especializados, puedes recargar tus envases con productos que cuidan de tu hogar y del medio ambiente.
                </p>

                <h2>3. Un paso hacia un mundo más limpio 🌍</h2>
                <p>
                    Cada vez que rellenas un envase, estás contribuyendo a disminuir la producción de nuevos plásticos. Este pequeño gesto se suma a un impacto positivo en nuestro planeta, reduciendo la contaminación y protegiendo nuestros océanos y ecosistemas.
                </p>

                <h2>4. Ahorra mientras cuidas el medio ambiente 💸</h2>
                <p>
                    Reutilizar no solo es bueno para el planeta, también lo es para tu bolsillo. Al rellenar tus envases, estás reduciendo costos al evitar comprar nuevos contenedores. En <strong>DeterMiau</strong>, creemos que cuidar el medio ambiente no debería ser costoso.
                </p>

                <div className="tips-extra">
                    <h3>💡 Tips adicionales:</h3>
                    <ul>
                        <li>
                            <strong>Etiqueta tus envases:</strong> Asegúrate de identificar cada envase con el producto que contiene para evitar confusiones.
                        </li>
                        <li>
                            <strong>Limpia tus envases antes de rellenar:</strong> Lava los envases vacíos para mantener la calidad de los productos.
                        </li>
                        <li>
                            <strong>Comparte la idea:</strong> Invita a amigos y familiares a reutilizar envases y a unirse al movimiento ecoamigable.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

// Incluye componentes en `tipsData`
const tipsData = [
    {
        id: 1,
        title: "Cómo mantener tu ropa blanca impecable 🧺",
        content: <RopaBlancaDetail />,
    },
    {
        id: 2,
        title: "El poder del Aceite de Pino 🌲",
        content: <PinoDetail />,
        image: PinoImg,
    },
    {
        id: 3,
        title: "Limpieza ecoamigable 🌍",
        content: <EcoFriendlyDetail />,
        image: RRR,
    },
    {
        id: 4,
        title: "Símbolo de lavado ⚠️",
        content: <SimbolosDeLavado />,
        image: RRR,
    },
];


function TipDetail() {
    const { id } = useParams();
    const tip = tipsData.find((tip) => tip.id === parseInt(id));

    if (!tip) {
        return <p>Tip no encontrado.</p>;
    }

    return (
        <div className="tip-detail-container">
            <p className="tip-detail-content">{tip.content}</p>
        </div>
    );
}

export default TipDetail;
