import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyAKFe7ryL95VdGI3wLncbnvLMdl2ZTUM_4",
    authDomain: "dispensador-v8.firebaseapp.com",
    databaseURL: "https://dispensador-v8-default-rtdb.firebaseio.com",
    projectId: "dispensador-v8",
    storageBucket: "dispensador-v8.firebasestorage.app",
    messagingSenderId: "766058788999",
    appId: "1:766058788999:web:7494312c227d460d272022",
    measurementId: "G-2P3J98BPC9"
  };
// Inicializa Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getDatabase(app); // Usa getDatabase(app) para Realtime Database
