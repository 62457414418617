import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './Card.css';

function ProductCard({ title, image, type, description }) {
    const [isModalOpen, setIsModalOpen] = useState(false); // Estado para el modal

    const handleBuyClick = () => {
        setIsModalOpen(true); // Abre el modal
    };

    const handleCloseModal = () => {
        setIsModalOpen(false); // Cierra el modal
    };

    // Modal con React Portals
    const modalContent = isModalOpen && (
        <div className="modal-overlay">
            <div className="modal-content">
                <h3 className="modal-title">Despachadores</h3>
                <p>
                    DeterMiau se preocupa por el cuidado del medio ambiente 🌍. Por eso, hemos implementado despachadores inteligentes que te permiten rellenar tus envases con nuestros productos, reduciendo el desperdicio de plástico y cuidando nuestro planeta 💚.
                </p>
                <p>¡Gracias por ser parte del cambio! 🌍🐾😺</p>
                <p>
                    🌟 Visita el despachador más cercano, recarga tu envase y experimenta la calidad superior que DeterMiau tiene para ofrecer. 🧴✨
                </p>
                <button className="modal-button" onClick={handleCloseModal}>
                    Cerrar
                </button>
            </div>
        </div>
    );

    return (
        <>
            <div className="card">
                <h2 className="card-title">{title}</h2>
                <img src={image} alt={title} className="card-image" />
                <span className="card-type">{type}</span>
                <p className="card-description">{description}</p>
                <button className="card-button" onClick={handleBuyClick}>
                    Comprar
                </button>
            </div>
            {ReactDOM.createPortal(modalContent, document.getElementById('modal-root'))}
        </>
    );
}

export default ProductCard;
