import React from 'react';
import './Modal.css'; // Asegúrate de tener este archivo CSS

const Modal = ({ show, handleClose, text }) => {
    return (
        <div className={`modal ${show ? 'show' : ''}`} onClick={handleClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <span className="close" onClick={handleClose}>&times;</span>
                <p>{text}</p>
            </div>
        </div>
    );
};

export default Modal;