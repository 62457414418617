import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import emailjs from 'emailjs-com'; // Importa EmailJS
import 'leaflet/dist/leaflet.css';
import './Contacto.css';

// Modal simple
function Modal({ isOpen, onClose, message }) {
    if (!isOpen) return null;
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <p>{message}</p>
                <button onClick={onClose} className="modal-button">Cerrar</button>
            </div>
        </div>
    );
}

const CustomIcon = new L.Icon({
    iconUrl: require('../../img/LogoMiauSinLetras.png'),
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    popupAnchor: [0, -40],
});

function ChangeMapView({ center }) {
    const map = useMap();
    map.setView(center, map.getZoom());
    return null;
}

function Contacto() {
    const [dispensadores, setDispensadores] = useState([
        { id: 1, nombre: "Dispensador Central", direccion: "Calle Atzayacatl", coordenadas: [19.413867642348485, -99.05466228635466] },
        
    ]);

    const [selectedDispensador, setSelectedDispensador] = useState(dispensadores[0]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            'DeterMiau124', // Reemplaza con tu Service ID
            'template_zima4y2', // Reemplaza con tu Template ID
            e.target,
            'jqYUYknRLSXFL1P7_' // Reemplaza con tu Public Key de EmailJS
        )
        .then(
            (response) => {
                console.log('Correo enviado exitosamente:', response.status, response.text);
                setModalMessage('Gracias por contactarnos. Te responderemos pronto.');
                setIsModalOpen(true);
            },
            (error) => {
                console.error('Error al enviar el correo:', error);
                setModalMessage('Ocurrió un error al enviar el correo. Intenta nuevamente más tarde.');
                setIsModalOpen(true);
            }
        );
        e.target.reset(); // Limpia el formulario
    };

    return (
        <div className="contacto-container">
            {/* Modal */}
            <Modal 
                isOpen={isModalOpen} 
                onClose={() => setIsModalOpen(false)} 
                message={modalMessage} 
            />

            {/* Formulario de contacto */}
            <div className="formulario-container">
                <h3 className="formulario-title">Contáctanos</h3>
                <form id="contact-form" onSubmit={handleSubmit}>
                    <input type="hidden" name="contact_number" value="123456" />
                    <div className="form-group">
                        <label className="form-label" htmlFor="user_name">Nombre:</label>
                        <input
                            className="form-input"
                            type="text"
                            id="user_name"
                            name="user_name"
                            placeholder="Tu nombre"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label className="form-label" htmlFor="user_email">Correo Electrónico:</label>
                        <input
                            className="form-input"
                            type="email"
                            id="user_email"
                            name="user_email"
                            placeholder="Tu correo electrónico"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label className="form-label" htmlFor="message">Mensaje:</label>
                        <textarea
                            className="form-textarea"
                            id="message"
                            name="message"
                            placeholder="Escribe tu mensaje aquí..."
                            required
                        ></textarea>
                    </div>
                    <button className="form-button" type="submit">Enviar</button>
                </form>
            </div>
        </div>
    );
}

export default Contacto;
