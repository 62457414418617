import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './tipDeterMiau.css';
import RopaBlanca from '../../img/blog_img/Ropablanca.png'
import PinoImg from '../../img/Pino.png';
import RRR from '../../img/RRR.png';
import simbolosLavado from '../../img/blog_img/simbolos.jpg'

const tipsData = [
    {
        id: 1,
        title: "Cómo mantener tu ropa blanca impecable 🧺",
        description: "Descubre cómo evitar que tus prendas blancas se amarillen.",
        image: RopaBlanca,
    },
    {
        id: 2,
        title: "El poder del Aceite de Pino 🌲",
        description: "Descubre los beneficios de limpiar con Pino DeterMiau",
        image: PinoImg,
    },
    {
        id: 3,
        title: "Limpieza ecoamigable 🌍",
        description: "Reutiliza tus envases con nuestros despachadores automáticos",
        image: RRR,
    },
    {
        id: 4,
        title: "Símbolo de lavado ⚠️",
        description: "Descubre el significado de los símbolos de lavado y aprende a cuidar tus prendas de la mejor manera.",
        image: simbolosLavado,
    },
];

function Tips() {
    const [searchTerm, setSearchTerm] = useState("");

    const filteredTips = tipsData.filter((tip) =>
        tip.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="tips-container">
            <div className='contenedor'>
            <h2 className="tips-title">Blog DeterMiau 🐾</h2>
            <input
                type="text"
                className="search-bar"
                placeholder="Buscar..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="tips-grid">
                {filteredTips.map((tip) => (
                    <div key={tip.id} className="tip-card">
                        <img src={tip.image} alt={tip.title} className="tip-image" />
                        <h3 className="tip-title">{tip.title}</h3>
                        <p className="tip-description">{tip.description}</p>
                        <Link to={`/Blog/${tip.id}`} className="tip-button">
                            Leer más
                        </Link>
                    </div>
                ))}
            </div>
            </div>
        </div>
    );
}

export default Tips;
