import React, { useState, useEffect } from 'react';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { ref, onValue, update, get, set } from 'firebase/database';
import { db, auth } from '../firebaseConfig'; // Asegúrate de que la ruta de importación sea correcta
import 'leaflet/dist/leaflet.css';
import './Dispensadores.css'; // Importa los estilos asociados
import Modal from './Modal';

// Importar ChartJS
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'chart.js';
Chart.register(ChartDataLabels);

// Icono personalizado para el marcador del mapa
const CustomIcon = new L.Icon({
  iconUrl: require('../../img/LogoMiauSinLetras.png'),
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  popupAnchor: [0, -40],
});

// Componente para cambiar la vista del mapa al dispensador seleccionado
function ChangeMapView({ center }) {
  const map = useMap();
  map.setView(center, map.getZoom());
  return null;
}

const Dispensadores = () => {
  const [dispensadores, setDispensadores] = useState([]);
  const [selectedDispensador, setSelectedDispensador] = useState(null);
  const [productos, setProductos] = useState([]);
  const [userPoints, setUserPoints] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalText, setModalText] = useState('');

  useEffect(() => {
    const dispensadoresRef = ref(db, 'DeterMiau');
    onValue(dispensadoresRef, (snapshot) => {
      const data = snapshot.val();
      console.log('Datos recibidos:');
      console.log(data);
      const dispensadoresArray = [];
      Object.keys(data).forEach((usuario) => {
        const usuarioDispensadores = data[usuario];
        Object.keys(usuarioDispensadores).forEach((dispensadorId) => {
          const dispensador = usuarioDispensadores[dispensadorId];
          const ubicacion = dispensador.Ubicacion;
          if (ubicacion) {
            dispensadoresArray.push({
              id: `${usuario}_${dispensadorId}`,
              nombre: ubicacion.Nombre,
              direccion: ubicacion.Direccion,
              coordenadas: [ubicacion.Lati, ubicacion.Long],
              usuarioId: usuario,
              dispensadorId: dispensadorId, // Añadimos el ID del dispensador para referencia
            });
          }
        });
      });
      setDispensadores(dispensadoresArray);
    });
  }, []);

  useEffect(() => {
    if (dispensadores.length > 0 && !selectedDispensador) {
      setSelectedDispensador(dispensadores[0]);
    }
  }, [dispensadores]);

  // Obtener productos del dispensador seleccionado
  useEffect(() => {
    if (selectedDispensador) {
      const productosRef = ref(
        db,
        `DeterMiau/${selectedDispensador.usuarioId}/${selectedDispensador.dispensadorId}/Configuracion/Productos`
      );
      onValue(productosRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const processedData = Object.keys(data).map(key => {
              const producto = data[key];
              return {
                  ...producto,
                  Cantidad: producto.Bloqueo ? 0 : producto.Cantidad
              };
          });
        setProductos(processedData);
          //setProductos(data);
        } else {
          setProductos([]);
        }
      });
    }
  }, [selectedDispensador]);

  // Función para dividir los productos en grupos de 8
  const dividirProductos = (productos) => {
    const grupos = [];
    for (let i = 0; i < productos.length; i += 8) {
      grupos.push(productos.slice(i, i + 8));
    }
    return grupos;
  };

  // Preparar los datos para las gráficas
  const gruposDeProductos = dividirProductos(productos);

  // Obtener puntos del usuario actual
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const userRef = ref(db, `users/${user.uid}`);
      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        if (data && data.credit) {
          setUserPoints(data.credit);
        }
      });
    }
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };  

  // Función para aplicar puntos
  const applyPoints = async () => {
    if (!selectedDispensador) {
        setModalText('No tienes puntos suficientes para aplicar.');
        setShowModal(true);
        return;
    }

    if (userPoints === 0) {
      setModalText('No tienes puntos suficientes para aplicar.');
      setShowModal(true);
      return;
    }

    const pesos = userPoints * 0.1; // Conversión: 1 punto = 10 centavos

    // Actualizar el crédito del dispensador en Firebase
    const dispensadorRef = ref( db, `DeterMiau/${selectedDispensador.usuarioId}/${selectedDispensador.dispensadorId}/Configuracion/Credito`);

    try {
      const snapshot = await get(dispensadorRef); // Obtén los datos actuales del dispensador
      const currentCredito = snapshot.val() || 0; // Obtén el crédito actual o 0 si no existe

      // Actualizar el nuevo crédito
      await set(dispensadorRef, currentCredito + pesos );

      // Restar puntos al usuario en Firebase
      const userRef = ref(db, `users/${auth.currentUser.uid}/credit`);
      await set(userRef, 0); // Reiniciar puntos del usuario en la base de datos
      setUserPoints(0); // Reiniciar puntos del usuario localmente

      
      setModalText(`Se aplicaron $${pesos.toFixed(2)} al dispensador seleccionado.`);
      setShowModal(true);

    } catch (error) {
      console.error('Error al aplicar puntos:', error);
      setModalText('Ocurrió un error al aplicar los puntos. Por favor, intenta nuevamente.');
      setShowModal(true);
    }
};

// return (
//   <div>
//   <EnConstruccion/>
// </div>
// );

  return (
    
    <div className='final'>
      <div className="dispensadores-container">
        {/* Lista de dispensadores */}
        <div className="dispensadores-lista">
          <h3 className="dispensadores-title">Dispensadores</h3>
          <ul>
            {dispensadores.map((dispensador) => (
              <li
                key={dispensador.id}
                onClick={() => setSelectedDispensador(dispensador)}
                className={`dispensador-item ${
                  selectedDispensador && selectedDispensador.id === dispensador.id ? 'activo' : ''
                }`}
              >
                {dispensador.nombre}
              </li>
            ))}
          </ul>
        </div>

        {/* Mapa que muestra la ubicación del dispensador seleccionado */}
        <div className="mapa-container">
        {selectedDispensador && (
          <MapContainer
            center={selectedDispensador.coordenadas}
            zoom={16}
            style={{ height: '100%', width: '100%' }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution="&copy; OpenStreetMap contributors"
            />
            <ChangeMapView center={selectedDispensador.coordenadas} />
            <Marker position={selectedDispensador.coordenadas} icon={CustomIcon}>
              <Popup>
                <strong>{selectedDispensador.nombre}</strong>
                <br />
                {selectedDispensador.direccion}
              </Popup>
            </Marker>
          </MapContainer>
        )}
        </div>
      </div>
      
      <div className="TituloGraficas"> 
        <h3 class="subrayado">Productos en el dispensador</h3> 
        <p className="TituloText">💡 En esta sección, podrás observar la cantidad de producto disponible en el dispensador 📊🧴 sin tener que salir de casa!!! 🏡.</p> 
      </div>

      {/* Sección de gráficas */}
      <div className="ContenedorG">
        <div className="graficas-container">
          {gruposDeProductos.map((grupo, index) => {
            // Preparar los datos para cada gráfica

            // Los labels serán los nombres de los productos junto con el precio
            const labels = grupo.map((producto) => [producto.Nombre, `$${producto.Precio}/L`]);

            // Datos de cantidades
            //const cantidades = grupo.map((producto) => producto.Cantidad);
            // Truncar datos a un decimal
            const cantidades = grupo.map((producto) => { return Number(producto.Cantidad.toFixed(1)); });

            // Colores para las barras
            const backgroundColors = [
              'rgba(255, 99, 132, 0.3)',
              'rgba(255, 159, 64, 0.3)',
              'rgba(255, 205, 86, 0.3)',
              'rgba(75, 192, 192, 0.3)',
              'rgba(54, 162, 235, 0.3)',
              'rgba(153, 102, 255, 0.3)',
              'rgba(54, 162, 235, 0.3)',
              'rgba(201, 203, 207, 0.3)',
            ];

            const borderColors = [
              'rgb(255, 99, 132)',
              'rgb(255, 159, 64)',
              'rgb(255, 205, 86)',
              'rgb(75, 192, 192)',
              'rgb(54, 162, 235)',
              'rgb(153, 102, 255)',
              'rgb(54, 162, 235)',
              'rgb(201, 203, 207)',
            ];

            const windowWidth = window.innerWidth;
            let barThicknessValue = 50; // Valor por defecto para pantallas grandes
            if (windowWidth <= 470) {
              barThicknessValue = 20; // Valor más delgado para pantallas móviles
            }

            // Configuración de la gráfica
            const data = {
              labels: labels,
              datasets: [
                {
                  label: '',
                  data: cantidades,
                  backgroundColor: backgroundColors.slice(0, cantidades.length),
                  borderColor: borderColors.slice(0, cantidades.length),
                  borderWidth: 1,
                  barThickness: barThicknessValue,
                  maxBarThickness: 50,
                },
              ],
            };

            const options = {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false, // Ocultar la leyenda
                },
                tooltip: {
                  callbacks: {
                    afterBody: function (context) {
                      const index = context[0].dataIndex;
                      const precio = grupo[index].Precio;
                      return `Precio: $${precio}/Litro`;
                    },
                  },
                },
                datalabels: {
                  display: true,
                  align: 'end',
                  anchor: 'end',
                  formatter: function (value, context) {
                    const producto = grupo[context.dataIndex];
                    return `${value}L`;
                    //return `${producto.Nombre}: ${value}L`;
                  },
                  color: '#000',
                  font: {
                    weight: 'bold',
                  },
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  suggestedMax: Math.max(...cantidades) + 10, // Añadir un margen superior
                  title: {
                    display: false,
                    text: 'Litros',
                  },
                },
                x: {
                  title: {
                    display: false,
                  },
                  ticks: {
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0,
                    font: {
                      size: windowWidth <= 470 ? 7 : 12, // Ajustar el tamaño de fuente en móviles
                    },
                    callback: function (value, index) {
                      const label = this.getLabelForValue(value);
                      // Combinar el nombre y el precio en líneas separadas
                      return label;
                    },
                  },
                },
              },
            };

            return (
              <div key={index} className="grafica">
                <Bar data={data} options={options} />
              </div>
            );
          })}
        </div>
      </div>

      {/* <div className="TituloGraficas"> 
        <h3 class="subrayado">Puntos x Producto</h3> 
        <p className="TituloText">1. Crea una cuenta DeterMiau</p> 
        <p className="TituloText">2. Mas adelante podras conseguir puntos.</p> 
        <p className="TituloText">3. Cuando conviertas tus puntos aparecera credito en el dispensador seleccionado.</p> 
        <p className="TituloText">4. Recuerda llevar tu envase.</p> 
      </div>
      
      <div className="points-container">
        <h4>Tienes {userPoints} puntos</h4>
        <div>
          <button class="btn" onClick={applyPoints}><i class="animation"></i>Convertir puntos<i class="animation"></i>
          </button>
        </div>
      </div> */}
      <Modal show={showModal} handleClose={handleCloseModal} text={modalText} />
      
    </div>
    
  );
};

export default Dispensadores;
