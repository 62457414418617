import React from 'react';
import './footer.css';
import { Link } from 'react-router-dom';
import { FaFacebook } from 'react-icons/fa';

const PieDePagina = () => (
    <footer className="footer">
        <div className="footer-content">
            <div className="contact-section">
                <Link to="/contacto" className="contact-button">Contacto 📞</Link>
            </div>
            <div className="social-section">
                <a href="https://www.facebook.com/profile.php?id=100092626162491" className="social-link" target="_blank" rel="noopener noreferrer">
                    <FaFacebook className="social-icon" /> Síguenos en Facebook
                </a>
            </div>
        </div>
    </footer>
);

export default PieDePagina;
