import React from 'react';
import './Productos.css';
import ProductCard from '../Card/Card'

import DeterMiau from '../../img/DeterMiau.png';
import DeterMiauP from '../../img/DeterMiauPlus.png';
import SuaveMiau from '../../img/SuaveMiau.png';
import SuavizanteBebe from '../../img/SuavizanteBebe.png';
import SuavizantePrimavera from '../../img/SuavizantePrimavera.png'
import Manos1 from '../../img/JabonDeManos4.png'
import Manos2 from '../../img/ManzanaCanela2.png'
import Mascotas from '../../img/Mascotas.png'
import trastes from '../../img/trastes.png'
import DeterconImg from '../../img/Detercon.png'
import DesengrasanteImg from '../../img/Desengrasante.png'
import LavandaImg from '../../img/LavandaBotella.png'
import AquaMiauImg from '../../img/AquaMiau.png'
import PinoImg from '../../img/Pino.png'
import Cloro from '../../img/Cloro.png'


const productos = [
    {
        title: "DeterMiau",
        image: DeterMiau, // Reemplaza con la ruta real
        description: "DeterMiau es el detergente líquido diseñado para cuidar de tus colores. Mantén tus prendas como nuevas, evitando el desgaste y asegurando que los tonos se mantengan vivos y radiantes. Ideal para quienes buscan proteger su ropa con suavidad y eficacia.",
        type: "Detergente",
    },
    {
        title: "DeterMiau+",
        image: DeterMiauP, // Reemplaza con la ruta real
        description: "DeterMiau+ combina el cuidado avanzado con el poder del abrillantador óptico. Este detergente líquido no solo elimina las manchas más difíciles, sino que también aporta un brillo incomparable a tus prendas, resaltando su vitalidad y frescura en cada lavada.",
        type: "Detergente",
    },
    {
        title: "OxiMiau",
        image: DeterMiauP
        , // Reemplaza con la ruta real
        description: "OxiMiau combina el poder del oxígeno activo con una fórmula suave para eliminar manchas difíciles. Renueva tus prendas blancas y de colores brillantes con un detergente que asegura frescura y resultados perfectos.",
        type: "Detergente",
    },
    {
        title: "Primavera",
        image: SuavizantePrimavera, // Reemplaza con la ruta real
        description: "El suavizante Primavera ofrece una fragancia floral fresca y duradera gracias a sus microcápsulas de perfume, que liberan aroma gradualmente. Además, deja tus prendas suaves y esponjosas, brindando comodidad en cada uso.",
        type: "Suavizante",
    },
    {
        title: "Bebe",
        image: SuavizanteBebe, // Reemplaza con la ruta real
        description: "El suavizante Bebe te envuelve con su delicado aroma, diseñado para brindar una sensación relajante y duradera en cada lavada. Ideal para quienes buscan suavidad y frescura en sus prendas.",
        type: "Suavizante",
    },    
    {
        title: "SuaveMiau",
        image: SuaveMiau, // Reemplaza con la ruta real
        description: "SuaveMiau transforma tus lavadas con microcápsulas de fragancia que se activan con el movimiento. Proporciona una suavidad extraordinaria y un aroma fresco que dura todo el día.",
        type: "Suavizante",
    },
    {
        title: "Manzana Verde",
        image: Manos1, // Reemplaza con la ruta real
        description: "El jabón de manos Manzana Verde combina la frescura de su fragancia con los beneficios del aloe vera, que hidrata y suaviza tu piel en cada lavada. Ideal para mantener tus manos limpias y cuidadas.",
        type: "Jabón para manos",
    },
    {
        title: "Manzana Canela",
        image: Manos2, // Reemplaza con la ruta real
        description: "Disfruta de la cálida combinación de manzana y canela en un jabón enriquecido con aloe vera. Limpia profundamente mientras cuida y protege tus manos, dejando un aroma acogedor.",
        type: "Jabón para manos",
    },
    {
        title: "Mascotas",
        image: Mascotas, // Reemplaza con la ruta real
        description: "El champú para Mascotas cuida el pelaje de tus compañeros con una fórmula suave y efectiva. Ideal para mantenerlos limpios, frescos y felices.",
        type: "Shampoo",
    },
    {
        title: "Trastes",
        image: trastes, // Reemplaza con la ruta real
        description: "Lava tus platos con el poder de limpieza de nuestro jabón Trastes. Eficaz contra grasa y residuos, dejando tus utensilios brillantes y listos para usar.",
        type: "Jabón",
    },
    {
        title: "Detercon",
        image: DeterconImg, // Reemplaza con la ruta real
        description: "Detercon es tu aliado contra las manchas más resistentes. Perfecto para limpiezas profundas con excelentes resultados.",
        type: "Detercon",
    },
    {
        title: "Desengrasante",
        image: DesengrasanteImg, // Reemplaza con la ruta real
        description: "El desengrasante DeterMiau está diseñado para un uso continuo sin necesidad de guantes. Su fórmula innovadora es suave con la piel pero implacable contra la grasa, dejando tus superficies impecables y libres de residuos con cada aplicación.",
        type: "Desengrasante",
    },
    {
        title: "Lavanda",
        image: LavandaImg, // Reemplaza con la ruta real
        description: "El multiusos Lavanda combina limpieza profunda con un aroma relajante. Ideal para mantener tu hogar fresco y libre de suciedad.",
        type: "Multiusos",
    },
    {
        title: "AquaMiau",
        image: AquaMiauImg, // Reemplaza con la ruta real
        description: "AquaMiau limpia a fondo y deja un brillo fresco en cada rincón de tu hogar. Su fórmula versátil lo hace ideal para cualquier superficie.",
        type: "Multiusos",
    },
    {
        title: "Pino",
        image: PinoImg, // Reemplaza con la ruta real
        description: "El limpiador Pino de DeterMiau está elaborado con aceite natural de pino, conocido por sus propiedades desinfectantes y desodorizantes. Este producto no solo limpia profundamente, sino que también elimina gérmenes y bacterias, dejando tus espacios impecables y libres de olores desagradables. Ideal para mantener tu hogar saludable y fresco.",
        type: "Multiusos",
    },
    {
        title: "Cloro",
        image: Cloro, // Reemplaza con la ruta real
        description: "El cloro DeterMiau desinfecta y limpia profundamente, eliminando bacterias y gérmenes para un hogar más seguro.",
        type: "Cloro",
    },
];


function Productos() {
    return (
        <div className="productos-grid">
            {productos.map((product, index) => (
                <ProductCard
                    key={index}
                    title={product.title}
                    image={product.image}
                    type={product.type}
                    description={product.description}
                />
            ))}
        </div>
    );
}


export default Productos;